import React from 'react'
import '../styles/collections.css'
import { Link } from "react-router-dom";

import skullys from "../images/skully-logo.png";
import ghostlys from "../images/ghostlys.png";
import pops from "../images/pop-skullys.png";
import paintswap from "../images/paintswap.png";
import candle from "../images/candle.png";

function Collections() {
    return <div className="collection-cards">
        <div className = "card">
        <div className = 'card-art'>
        <a href="https://discord.gg/cultnfts"><img src={skullys} alt="discord"/></a>
        </div>
        <div className='card-title'>
        <h1>Skullys</h1>
        <p>Drawn in chalk to remember the path.</p>
        </div>
        <div className='card-body'>
        <p>Supply 1,000</p>
        </div>
        <div className='card-links'>
        <a href="https://paintswap.finance/marketplace/fantom/collections/skullys"><img src={paintswap} alt="paintswap"/></a>
        </div>

        </div>

        <div className = "card">
        <div className = 'card-art'>
        <a href="https://paintswap.finance/marketplace/fantom/collections/skullys"><img src={ghostlys} alt="discord"/></a>
        </div>
        <div className='card-title'>
        <h1>Ghostlys</h1>
        <p>Summoned to protect ancient ritual grounds.</p>
        </div>
        <div className='card-body'>
        <p>Supply 922</p>
        </div>
        <div className='card-links'>
        <a href="https://paintswap.finance/marketplace/fantom/collections/ghostlys"><img src={paintswap} alt="paintswap"/></a>
        </div>
        </div>
        <div className = "card">
        <div className = 'card-art'>
        <a href="https://paintswap.finance/marketplace/fantom/collections/pop-skullys"><img src={pops} alt="discord"/></a>
        </div>
        <div className='card-title'>
        <h1>Pop Skullys</h1>
        <p>Figurines displayed in homes and held for good luck.</p>
        </div>
        <div className='card-body'>
        <p>Minting</p>
        </div>
        <div className='card-links'>
        <a href="https://popskullys.com/"><img src={candle} alt="nftkey"/></a>
        <a href="https://paintswap.finance/marketplace/fantom/collections/pop-skullys"><img src={paintswap} alt="paintswap"/></a>
        <button>Mint</button>
        
        </div>
        </div>

    </div>
}

export default Collections