import '../styles/lore.css'
import React from 'react';
import { Link } from "react-router-dom";

function Text() {
    return <div className="lore-content">
    <div className='lore-text'>
    <h1>The relics are a part of their rituals.</h1>
    <h1>According to the scrolls, they use candles, chalk, and wands.</h1>
    <h1>There are books with more details but they are difficult to come by.</h1>
    </div>

    <div className='options'>
    <Link to="/relics"><button><p>How do you know so much?</p></button></Link>
    <Link to="/discover"><button><p>What is this cult?</p></button></Link>
    </div>
    </div>
}

export default Text