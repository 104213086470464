import '../styles/lore.css'
import React from 'react';
import { Link } from "react-router-dom";

function Text() {
    return <div className="lore-content">
    <div className='lore-text'>
    <h1>There are greater mysteries for you to unravel.</h1>
    <h1>Light a candle to see the way.</h1>
    </div>
    <div className='options'>
    </div>
    </div>
}

export default Text