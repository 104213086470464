import '../styles/lore.css'
import React from 'react';
import { Link } from "react-router-dom";

function Text() {
    return <div className="lore-content">
    <div className='lore-text'>
    <h1>The kids?</h1>
    <h1>Most of them study the scrolls in hopes of ascending..</h1>
    <h1>You can typically find them hanging around the auction house where they sell relics.</h1>
    </div>
    <div className='options'>
    <Link to="/relics"><button><p>What are these relics?</p></button></Link>
    <Link to="/mysteries"><button><p>How do you know so much?</p></button></Link>
    <Link to="/discover"><button><p>What is this cult?</p></button></Link>
    </div>
    </div>
}

export default Text