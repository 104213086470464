import '../styles/lore.css'
import React from 'react';
import { Link } from "react-router-dom";

function Text() {
    return <div className="lore-content">
    <div className='lore-text'>
    <h1>That is something you must discover on your own.</h1>
    </div>
    <div className='options'>
    </div>
    </div>
}

export default Text