import React from "react";
import '../styles/lore.css';
import Welcome from "../components/intro.js";



function Intro() {
    return <div className="lore-text">
    <Welcome/>
    </div>
}

export default Intro