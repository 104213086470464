import React from "react";
import '../styles/lore.css';
import Lore from "../components/7a.js";



function LoreText() {
    return <div className="lore-text">
    <Lore/>
    </div>
}

export default LoreText