import '../styles/lore.css'
import React from 'react';
import { Link } from "react-router-dom";

function Text() {
    return <div className="lore-content">
    <div className='lore-text'>
    <h1>They started experimenting with older texts and found a way to ascend.</h1>
    <h1>It became the only way to leave.</h1>
    </div>
    <div className='options'>
    <Link to="/everything"><button><p>Where do they go?</p></button></Link>
    <Link to="/discover"><button><p>What is this cult?</p></button></Link>
    </div>
    </div>
}

export default Text