import './App.css';
import Nav from "./components/nav.js";
import Footer from "./components/footer.js";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from "./pages/home.js";
import Intro from "./pages/intro.js";
import KingCold from "./pages/1b.js";
import WhatHappened from "./pages/1a.js";
import Centuries from "./pages/2a.js";
import Cult from "./pages/3a.js";
import Ex from "./pages/4a.js";
import Everything from "./pages/5a.js";
import Relics from "./pages/6a.js";
import Kids from "./pages/6b.js";
import Mysteries from "./pages/7a.js";
import Discover from "./pages/7b.js";

function App() {
 {
  return (
    <Router>
      <Nav />
      <Routes>
        <Route exact path="/" element ={<Home/>} />
        <Route exact path="/intro" element ={<Intro/>} />
        <Route exact path="/what-happend" element ={<WhatHappened/>} />
        <Route exact path="/king-cold" element ={<KingCold/>} />
        <Route exact path="/centuries" element ={<Centuries/>} />
        <Route exact path="/the-cult" element ={<Cult/>} />
        <Route exact path="/experimenting" element ={<Ex/>} />
        <Route exact path="/everything" element ={<Everything/>} />
        <Route exact path="/relics" element ={<Relics/>} />
        <Route exact path="/kids" element ={<Kids/>} />
        <Route exact path="/mysteries" element ={<Mysteries/>} />
        <Route exact path="/discover" element ={<Discover/>} />
      </Routes>
      <Footer />
    </Router>
  );
}
}
export default App;
