import '../styles/lore.css'
import React from 'react';
import { Link } from "react-router-dom";

function Text() {
    return <div className="lore-content">
    <div className='lore-text'>
    <h1>We have all been here for centuries.</h1>
    <h1>Some grew to love it while others grew bored.</h1>
    <h1>Over time we became relatively advanced. In a world without death, we all must coexist.
But they kept looking for something else.</h1>
    </div>
    <div className='options'>
    <Link to="/the-cult"><button><p>Who are they?</p></button></Link>
    <Link to="/experimenting"><button><p>What did they do?</p></button></Link>
    </div>
    </div>
}

export default Text