import '../styles/footer.css';
import React from 'react';
import candle from "../images/candle.png";
import telegram from "../images/telegram.png";
import x from "../images/x-icon.png";
import discord from "../images/discord.png";

function Footer() {
return <div className="footer">
<div className="social-links">
    <a href="https://twitter.com/cultnfts"><img src={x} alt="twitter"/></a>
    <a href="https://t.me/+azd0MvR8_nZjMTgx"><img src={telegram} alt="disccord"/></a>
    <a href="https://discord.gg/qXUppGGnun"><img src={discord} alt="disccord"/></a>
    <a href="https://dexscreener.com/fantom/0x29d226dd25dc6e7af29b65f915a6ecac045f4f63"><img src={candle} alt="game"/></a>
  </div>
  <div className='footer-text'><p>Cult NFTs</p></div>
</div>
}
export default Footer;