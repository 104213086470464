import '../styles/lore.css'
import React from 'react';
import { Link } from "react-router-dom";

function Text() {
    return <div className="lore-content">
    <div className='lore-text'>
    <h1>The Cult.</h1>
    </div>
    <div className='options'>
    <Link to="/experimenting"><button><p>What were they looking for?</p></button></Link>
    <Link to="/discover"><button><p>Who are they?</p></button></Link>
    </div>
    </div>
}

export default Text