import React from "react";
import '../styles/home.css';
import Header from "../components/welcome";
import Collections from "../components/collections.js";
import Sacrafice from "../components/sacrafice.js";


function Home() {
    return <div className="home-page">
        <Header />
        <Collections />
    </div>
}

export default Home;