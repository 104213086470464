import '../styles/lore.css'
import React from 'react';
import { Link } from "react-router-dom";

function Text() {
    return <div className="lore-content">
    <div className='lore-text'>
    <h1>People stopped dying. Time stood still.</h1>
    <h1>Eventually it brought us peace.</h1>
    </div>
    <div className='options'>
    <Link to="/centuries"><button><p>What do you mean?</p></button></Link>
    <Link to="/centuries"><button><p>How long have you been here?</p></button></Link>
    </div>
    </div>
}

export default Text