import React from 'react'
import '../styles/header.css'
import { Link } from "react-router-dom";
import Logo from "../images/studying.gif";

function Nav() {
    return <div className="header-container">

        <a href="https://dexscreener.com/fantom/0xf8c5187294ebd7ecdcd9cb9e7b2bcb46a3420288"><img src= {Logo} /></a>


    </div>
}

export default Nav;