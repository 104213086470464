import '../styles/lore.css'
import React from 'react';
import { Link } from "react-router-dom";

function Hello() {
    return <div className="lore-content">
    <div className='lore-text'>
    <h1>In a world ruled by fear, the meaning of life began to change. King Cold was the first to ascend. He used his will to confront the Devil.</h1>
    <h1>This is the day we were born.</h1>
    </div>
    <div className='options'>
    <Link to="/what-happend"><button><p>What happened?</p></button></Link>
    <Link to="/king-cold"><button><p>Who is King Cold?</p></button></Link>
    </div>
    </div>
}

export default Hello