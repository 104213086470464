import '../styles/lore.css'
import React from 'react';
import { Link } from "react-router-dom";

function Text() {
    return <div className="lore-content">
    <div className='lore-text'>
    <h1>No one knows where they go.</h1>
    <h1>They leave everything behind. Their lives, their homes, even their kids..</h1>
    <h1>The Cult lives on through them and the relics.</h1>
    </div>

    <div className='options'>
    <Link to="/relics"><button><p>What are these relics?</p></button></Link>
    <Link to="/kids"><button><p>What happens to the kids?</p></button></Link>
    <Link to="/thecult"><button><p>What is this cult?</p></button></Link>
    </div>
    </div>
}

export default Text